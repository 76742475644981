<template>
    <div class="container">
        <p>Chargements des données</p>
        <p class="progress-container">
            <progress id="file" max="100" :value="this.progressAnimation(this.progress)" class="bar"> 70% </progress>
        </p>
    </div>
</template>
<script>
export default {
    data() {
        return {
            progress: 0,
        }
    },
    methods: {
        progressAnimation(progress) {
            // console.log(this.progress);
            setTimeout(() => {
                if (this.progress < 100) {
                    this.progress += 2;
                    this.progressAnimation(this.progress);
                } else {
                    this.progress = 100;  
                }
            }, 2000);
            
            return progress;
        },
    },
}
</script>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    p {
        color: #fff;
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        margin: 0 auto;
        padding-top:20px;
    }
    .progress-container {
        
        margin: 0 auto;
        min-width: 300px;
        max-width: 1200px;
        padding: 0 20px;
    }
    progress {
        width: 100%;
        height: 10px;
        background: #fafafa;
        border-radius: 5px;
        box-shadow: 0 0 5px #ccc;
    }
</style>