<template>
<!-- Section 1 A propos -->
  <h3 class="title" @click="showSection('about')">A propos de moi
    <i class="fas fa-angle-down" v-if="this.display === 'about'"></i>
    <i class="fas fa-angle-up" v-else></i>
  </h3>
  <transition name="fade">
    <div v-if="this.display=== 'about' " class="box">
      Passionné de musique et d'informatique depuis l'adolescence, j'ai pu, après une première vie professionnelle dans le bâtiment en tant que peintre (CAP, BEP, BP), suivre un cursus certifiant qui m'a aidé à devenir professeur de guitare et de solfège.<br>
      Mon caractère entreprenant et curieux m'a permis d'intégrer différentes structures dans lesquelles j'ai pu améliorer ma pédagogie, créer des projets et partager ma passion avec conviction. En contact direct avec mes élèves (et leurs parents très souvent), j'ai pu développer mes compétences en écoute, communication et créativité. Je suis sans cesse amené à travailler en équipe, à gérer mon planning, à revoir mes méthodes d'enseignement pour m'adapter à chacun et à vendre mes compétences pour fidéliser mes élèves. J'ai pour habitude d'approfondir mes connaissances afin d'être plus efficace et plus polyvalent et à ne surtout pas vivre sur mes acquis.<br><br>
      Suite à un licenciement économique (résultat d'une année de Covid ne favorisant pas les métiers à contact humain), j'ai décidé de transformer cette perte d'emploi en occasion à saisir pour me reconvertir dans le domaine du développement web et c'est avec plaisir que je découvre cet univers.<br>
      Après une formation de développeur fullstack à Studi je cherche à me perfectionner dans ce domaine en découvrant de nouvelles technologies et différentes façons de travailler. C'est pourquoi je me suis tourné vers une licence CDA en alternance à la Wild Code School ce qui me permettrait d'affirmer mes connaissances et de pouvoir progresser en les mettant en pratique tout en apprenant de nouvelles notions au sein d'une équipe.<br>
      Ayant passé les tests techniques et étant officiellement accepté pour suivre cette formation je suis maintenant à la recherche d'un contrat de professionnalisation qui devra démarrer en Mars 2022 et durer un an. Pour plus d'informations n'hésitez pas à me contacter, je me ferai un plaisir d'échanger avec vous.<br>
    </div>
  </transition>
  <!-- Section 2 Projets -->
      <Projects/>
  <!-- Section 3 Compétences -->
    <Skills/>
  <!-- Section 4 CV -->
    <CV/>
</template>

<script>
// @ is an alias to /src
import  Skills  from '@/components/Skills.vue'
import Projects from '@/components/Projects.vue'
import CV from '@/components/CV.vue'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    Skills,
    Projects,
    CV,
  },

  data () {
    return {
      
    }
  },
  
  computed: mapState({
    display: state => state.display,
    load: state => state.load,
  }),

  methods: {
    showSection (section) {
      this.$store.dispatch('showSection', section)
    }
  },
  mounted() {
    document.title = 'Accueil - Portfolio de Guillaume Lepoetre'
  }
}
</script>

<style>
  .title {
    cursor: pointer;
    padding: 1.5em;
    background: #3333;
    max-width: 1200px;
    margin: 0 auto 10px;
    position: relative;
  }
  .title i{
    position: absolute;
    right: 0;
    top: -.2em;
    padding: 0.5em;
    font-size: 3em;
    color: #3333;
  }

  .box {
    background: #fafafa;
    padding: 1em;
    border-radius: 5px;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 10px;
    text-align: left;
  }
  
/* création de l'animation */
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s ease-in-out;
  }
  
</style>
