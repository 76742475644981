<template>
  <header>
    <div class="logo">
      <!-- <img src="./assets/logo-1-1.png" alt=""> -->
      <h1>Guillaume Lepoetre</h1>
      <h2>Développeur Fullstack</h2>
      <h4>Web et web-mobile</h4>
    </div>
    <div id="nav">
      <router-link to="/">Accueil</router-link> |
      <router-link to="/Contact">Me contacter</router-link>
    </div>
  </header>
  <router-view/>
  <footer>
    <hr>
    <div class="footer-container">
      <div class="footer-title">
        <p>Mes réseaux</p>
      </div>
      <div class="footer-content">
        <div class="footer-content-item">
          <a href="https://www.linkedin.com/in/guillaumelepoetre/" target="_blank">
            <img src="https://img.icons8.com/color/48/000000/linkedin.png" alt="linkedin">
          </a>
        </div>
        <div class="footer-content-item">
          <a href="https://github.com/guillaume60240" target="_blank">
            <img src="https://img.icons8.com/color/48/000000/github.png" alt="github">  
          </a>
        </div>
      </div>
      <div class="copyright">
        <p>Tous droits réservés © 2021 Guillaume Lepoetre</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

  created() {
    this.$store.dispatch('loadProjects'),
    this.$store.dispatch('loadTags'),
    this.$store.dispatch('loadProjectsTags'),
    this.$store.dispatch('loadCompetences')
  }
}
</script>


<style lang="scss">
body{
  background-color: #f5f5f5;
}

header{
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  background-image: url(./assets/header-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

header h1 {
  font-size: 2rem;
  letter-spacing: .4em;
  margin-bottom: 0;
  font-weight: 900;
}
header h2 {
  font-size: 1.5rem;
  letter-spacing: .4em;
  font-weight: 900;
  margin-bottom: 0;
}
header h4 {
  font-size: 1.2rem;
  letter-spacing: .3em;
  font-weight: 100;
  margin-bottom: 0;
}

// .logo {
//   width: 250px;
// }

// .logo img{
//   width: 100%;
// }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
footer {
  padding: 30px;
  background-color: #f5f5f5;
  color: #2c3e50;
  max-width: 1200px;
  margin: 0 auto;
  
  hr {
    border: 1px solid #2c3e50;
  }

  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    margin-bottom: 50px;
    
    padding: 0 20px;
  }
}

</style>
