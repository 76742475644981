<template>
    <h3 class="title"  @click="showSection('skills') ">Voir mes compétences
      <i class="fas fa-angle-down" v-if="this.display === 'skills'"></i>
      <i class="fas fa-angle-up" v-else></i>
    </h3>
    <transition name="fade">
        <div class="skills-container" v-if="this.display === 'skills' ">
          <template v-if="this.skillsLoaded === false">
            <Load/>
          </template>
          <template v-else>
            <div class="skill" 
                v-for="(skill, index) in this.skills"
                :key="index"
            >
                <div class="skill-container">
                <div class="skill-title">
                    <p>{{ skill.name }}</p>
                </div>
                <div class="skill-bar">
                    <div class="skill-bar-container">
                    <div class="skill-bar-fill" :style="{ width: calcSkill(skill.startLearn) + '%' }"></div>
                    </div>
                </div>
                </div>
            </div>
          </template>
        </div>
    </transition>
</template>

<script>
  import { mapState } from 'vuex'
  import Load from '@/components/partials/Load.vue'

  export default {
    name: 'Skills',
    components: {
      Load
    },

    data () {
        return {
            
        }
    },

    computed: mapState({
        skills: state => state.competences,
        skillsLoaded: state => state.skillsLoaded,
        display: state => state.display,
    }),

    methods: {
      calcSkill(date) {
          const dateNow = new Date();
          const dateStart = new Date(date);
          const diff = dateNow - dateStart;
          const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
          const result = ((diffDays + 60) / (365 * 2)) * 100;
          if (result > 100) {
              return 100;
          } else {
              return result;
          }
      },
      showSection(section) {
        this.$store.dispatch('showSection', section)
      }
    },
  }
</script>

<style scoped>
    .skills-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 200px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px 20px;
    background: #333;
  }
  .skill-container {
    display: flex;  
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /* max-width: 1200px; */
    padding: 0 20px;
  }
  .skill-title {
    font-size: 1.5em;
    color: aliceblue;
  }
  .skill-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-width: 300px;
    max-width: 1200px;
    padding: 0 20px;
  }
  .skill-bar-container {
    position: relative;
    width: 100%;
    height: 10px;
    background: #fafafa;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
  } 
  .skill-bar-container::before {
    content: '1 an |';
    position: absolute;
    top: -20px;
    left: 40%;
    color: aliceblue;
  }
  .skill-bar-container::after {
    content: '2 ans |';
    position: absolute;
    top: -20px;
    left: 83%;
    color: aliceblue;
  }
  .skill-bar-fill {
    height: 100%;
    background: #42b983;
    border-radius: 5px;
  }
  .skill {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 20px;
  }

</style>