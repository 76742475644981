<template>
    <div class="back" @click="backToProjects()">  
        <img src="../assets/fleche-vers-le-haut.png" alt=""> 
        <h4>Retour aux projets</h4>
    </div>
    <div class="project-container">   
        <div class="project">
            <hr>
            <h1>{{ this.project.name }}</h1>
            <div class="header">
                <div class="project-link" >
                    <a :href="this.project.url " target="_blank" rel="noopener noreferrer" v-if="this.project.status === 'published' ">
                        <img src="../assets/internet.png" alt="internet world logo">
                    </a>
                    <a v-if="this.project.github" :href="this.project.github " target="_blank" rel="noopener noreferrer">
                        <img src="../assets/github.png" alt="githublogo">
                    </a>
                </div> 
                <div class="tags-container">  
                    <span 
                        v-for="(tag, index) in project.tag" 
                        :key="index" 
                        class="tag"
                    >
                        <small>{{ getTag(tag) }}</small>
                    </span>
                </div>      
            </div>
            <div class="images-container">
                <div class="project-img-parent">
                    <div class="project-img-container">
                        <img  :src="(this.apiImages + this.images.secondaire_pictures[i].directus_files_id) + '.png'" :alt="(this.project.name)" class="project-img">
                    </div>
                </div>
                <div class="thumbnails-container"> 
                    <div class="thumbnail-parent" v-for="(image, index) in this.images.secondaire_pictures" :key="index">
                        <div class="thumbnail-container">
                            
                            <img :src="(this.apiImages + image.directus_files_id) + '.png'" :alt="(project.name)" 
                                class="project-img" 
                                :class="(i === index ? 'active' : '' )"
                                @click="changePicture(index)"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="project-description">
                <h4>Description du projet : </h4>
                <p v-html="this.project.description"></p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: mapState({
        project: state => state.selectedProject,
        images: state => state.images,
        apiImages: state => state.apiImages,
        tags: state => state.tags,
        projectsTags: state => state.projectsTags,
    }),

    data() {
        return {
            i: 0,
        }
    },

    methods: {
        backToProjects() {
            this.$store.dispatch('backToProjects')
        },
        changePicture(index) {
            this.i = index;
        },
        getTag (tag) {
            return this.tags.find(el => el.id === this.projectsTags.find(el => el.id === tag).tags_id).name;
        },
    }
}
</script>

<style scoped>
    .back {
        top: 10px;
        cursor: pointer;
        text-align: left;
        color: #fff;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .back img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        transform: rotate(-90deg);
    }

    .project-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #fff;
    }
    
    .project {
        position: relative;
        width: 100%;
        
        padding: 1em;
        
    }
    
    .images-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .project-img-parent {
        height: 500px;
    }
    
    .project-img-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }  
    
    .project-img {
        max-height: 100%;
        max-width: 100%;
    }

    .thumbnails-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        width: 100%;
        margin-top: 10px;
    }

    .thumbnail-parent {
        height: 50px; 
        overflow: hidden;
    }

    .thumbnail-container {
        width: 100px;
        height: 100px;
        padding: 10px;
    }

    .thumbnail {
        max-height: 100%;
        max-width: 100%;
    }

    .active{
        box-shadow: 0 0 10px rgb(248, 246, 246);
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        background: #fff;
    }

    .project-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 20%;
        bottom: 0;
        left: 0;
        padding-top: 10px;
        text-align: left;
    }

    .project-link a {
        text-decoration: none;
        color: #333;
    }

    .project-link a img {
        width: 40px;
        height: 40px;
    }
    
    .project-link a img:last-child {
        margin-left: 10px;
    }

    .tags-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        padding: 0 20px;
    }
    .tag {
        padding: 5px 10px;
        margin: 5px;
        margin-left: 20px;
        border: #15754a solid 1px;
        border-radius: 10px;
        max-width: 90px;
        background: #42b983;
        color: aliceblue;
    }

    .project-description {
        margin-top: 30px;
        text-align: left;
    }
</style>