<template>
    <h3 class="title" @click="showSection('projects')">Voir mes projets
      <i class="fas fa-angle-down" v-if="this.display === 'projects'"></i>
      <i class="fas fa-angle-up" v-else></i>
    </h3>
    
      <transition name="fade">
          <div class="projects-container"  v-if="this.display === 'projects' ">
            <template v-if="this.projectsLoaded === false">
              <Load/>
            </template>
            <template v-else>
              <template v-if="this.showDetails === true">
                <ProjectDetails :project="this.selectedProject" @close="showDetails = false"/>
              </template>
              <template v-else>
                <div class="project" 
                    v-for="(project, index) in this.projects" 
                    :key="index"
                >
                    <div>
                        <p class="projectTitle">{{ project.name }}</p>
                        <p @click="showDetailsProject(project)" class="show"><span>Voir plus</span> <img src="../assets/fleche-vers-le-haut.png" alt=""> </p>
                        <img :src="(this.apiImages + project.principal_picture + '.png')" :alt="(project.name)" class="project-img"><br>
                        <div class="tag-container">
                            <div 
                                v-for="(tag, index) in project.tag" 
                                :key="index" 
                                class="tag"
                            >
                                <small>{{ this.getTags(tag) }}</small>
                            </div>
                        </div>
                        <div class="project-link" >
                          <a :href="project.url " target="_blank" rel="noopener noreferrer" v-if="project.status === 'published' ">
                              <img src="../assets/internet.png" alt="internet world logo">
                          </a>
                          <a v-if="project.github" :href="project.github " target="_blank" rel="noopener noreferrer">
                              <img src="../assets/github.png" alt="githublogo">
                          </a>
                        </div>
                      </div>
                  </div>
              </template>
            </template>
          </div>
      </transition>
</template>

<script>
import { mapState } from 'vuex'
import Load from '@/components/partials/Load.vue'
import ProjectDetails from '@/components/ProjectDetails.vue'
export default {
  
    data () {
    return {
      
    }
  },

  components: {
    Load,
    ProjectDetails,
  },

  computed: mapState({
    projects: state => state.projects,
    apiImages: state => state.apiImages,
    tags: state => state.tags,
    projectsTags: state => state.projectsTags,
    projectsLoaded: state => state.projectsLoaded,
    display: state => state.display,
    showDetails: state => state.showDetails,
  }),

  methods: {
    getImage (screen) {
      return `${this.$store.state.api}/assets/${screen}?key=principal`
    },
    getTags (tag) {
      return this.tags.find(el => el.id === this.projectsTags.find(el => el.id === tag).tags_id).name;
    },
    showSection(section) {
      this.$store.dispatch('showSection', section)
    },
    showDetailsProject(project) {
      this.$store.dispatch('showDetailsProject', project)
    }
  }
}
</script>


<style scoped>
  
  .projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
    margin-bottom: 10px;
    max-width: 1200px;
    padding: 0 20px;
    padding-bottom: 20px;
    background: #333;
  }

  .project {
    position: relative;
    /* width: 30%; */
    width: 390px;
    /* margin: 0 auto; */
    margin-top: 20px;
    padding: 1em;
    background: #fafafa;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
  }

  .project:hover {
    box-shadow: 0 0 10px #ccc;
  }

  .projectTitle {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: .5em;
  }

  .show {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .show img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        transform: rotate(90deg);
  }

  .project-img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 1em;
  }

  .tag-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 50px;
    max-width: 1200px;
    padding: 0 20px;
  }

  .tag {
    padding: 5px 10px;
    margin: 5px;
    border: #15754a solid 1px;
    border-radius: 10px;
    max-width: 90px;
    background: #42b983;
    color: aliceblue;
  }

  .project-link{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .project a {
    text-decoration: none;
    color: #333;
  }

  .project a img {
    width: 40px;
    height: 40px;
  }

  .project a img:last-child {
    margin-left: 10px;
  }
  
  .project a:hover {
    text-decoration: underline;
  }

</style>