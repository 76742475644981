import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    projects: [],
    tags: [],
    projectsTags: [],
    competences: [],
    secondairesImages: [],
    files: [],
    showDetails: false,
    selectedProject: null,
    images: [],
    api: 'https://apidirectusportfolio.herokuapp.com',
    apiImages: 'https://portfoliofilesdirectus.s3.eu-west-3.amazonaws.com/',
    projectsLoaded: false,
    skillsLoaded: false,
    display: "about",
  },

  mutations: {
    loadProjects (state, projects) {
      state.projectsLoaded = true
      state.projects = projects.data
    },
    loadTags (state, tags) {
      state.tags = tags.data
    },
    loadProjectsTags (state, projectsTags) {
      state.projectsTags = projectsTags.data
    },
    loadCompetences (state, competences) {
      state.skillsLoaded = true
      state.competences = competences.data
    },
    loadFiles (state, files) {
      state.files = files.data
    },
    
    showSection (state, {section}){
      state.display = section;
    },
    loadImages (state, images) {
      state.showDetails = true
      state.secondairesImages = images
      console.log(state.showDetails)
    },
    showDetailsProject (state, [images, project]) {
      state.showDetails = true
      state.selectedProject = project
      state.images = images.data[0]
    },
    backToProjects (state) {
      state.showDetails = false
    }
  },

  actions: {
    loadProjects ({ commit }) {
      return axios.get('https://apidirectusportfolio.herokuapp.com/items/projects')
        .then(response => {
          commit('loadProjects', response.data)
        })
        .catch(error => {
          console.log(error)
      })
    },
    
    loadTags ({ commit }) {
      return axios.get('https://apidirectusportfolio.herokuapp.com/items/tags')
        .then(response => {
          commit('loadTags', response.data)
        })
        .catch(error => {
          console.log(error)
      })
    },

    loadProjectsTags ({ commit }) {
      return axios.get('https://apidirectusportfolio.herokuapp.com/items/projects_tags')
        .then(response => {
          commit('loadProjectsTags', response.data)
        } )   
        .catch(error => {
          console.log(error)
      } )
    },

    loadCompetences ({ commit }) {
      return axios.get('https://apidirectusportfolio.herokuapp.com/items/competences')
        .then(response => {
          commit('loadCompetences', response.data)
        })
        .catch(error => {
          console.log(error)
      })
    },

    loadFiles ({ commit }) {
      return axios.get('https://apidirectusportfolio.herokuapp.com/items/files')
        .then(response => {
          commit('loadFiles', response.data)
        })
        .catch(error => {
          console.log(error)
      })
    },
   
    showSection ({ commit }, section) {
      return commit('showSection', {section})
    },

    showDetailsProject ({ commit }, project) {
      return axios.get('https://apidirectusportfolio.herokuapp.com/items/projects?fields=secondaire_pictures.directus_files_id&filter[id][_eq]=' + project.id)
        .then(response => {
          commit('showDetailsProject', [response.data, project])
        })
        .catch(error => {
          console.log(error)
      })
    },

    backToProjects ({ commit }) {
      return commit('backToProjects')
    }
  },

  modules: {
  }
})
