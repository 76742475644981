<template>
    <h3 class="title"  @click="showSection('CV') ">Mon CV
        <i class="fas fa-angle-down" v-if="this.display === 'CV'"></i>
        <i class="fas fa-angle-up" v-else></i>
    </h3>
    <transition name="fade">
        <div class="CV-container" v-if="this.display === 'CV' ">
          
            <div class="cv-container">
                <object data="https://portfoliofilesdirectus.s3.eu-west-3.amazonaws.com/adea0fce-4c43-4162-8956-189f35eba5ff.pdf" type="text/html" codetype="application/pdf"></object>
            </div>
          
        </div>
    </transition>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'CV',

    data () {
        return {
            
        }
    },

    computed: mapState({
        display: state => state.display,
    }),

    methods: {
      showSection(section) {
        this.$store.dispatch('showSection', section)
      }
    },
  }
</script>

<style scoped>
    .cv-container {
        width: 100%;
        height: 900px;
        max-width: 1200px;
        margin: 0 auto;
    }
    .cv-container object {
        width: 100%;
        height: 100%;
    }
    
</style>